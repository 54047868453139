<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import HomeView from './components/HomeViewSimple.vue';
import BattlerView from './components/BattlerView.vue';

export default {
  data() {
    return {
      view: 'home',
    };
  },
  computed: {
    viewComponent() {
      return this.view === 'battle' ? BattlerView : HomeView;
    },
  },
  methods: {
    handleCommand(command) {
      if (command === 'BATTLE') {
        this.view = 'battle';
      }
    },
  },
};
</script>

<style>
html, body {
  color: #91c97b;
  background-color: rgb(15, 17, 15);
}

/* Custom styles for the Stacks Connect modal */
.stacks-modal {
  background-color: #2d2d2d !important;
  color: #ffffff !important;
}

.stacks-modal-header {
  background-color: #1a1a1a !important;
}

.stacks-modal-content {
  padding: 20px !important;
}


</style>
