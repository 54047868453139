<template>
  <div v-if="gameOver" class="victory-container">
    <p class="win-message">YOU WIN!</p>
    <p class="win-subtext">ENTER YOUR MINTING WALLET TO BE ADDED TO THE WHITELIST</p>
    <div v-if="showWinnerForm" class="winner-form">
      <input v-model="winnerName" placeholder="Enter your name" class="styled-input" />
      <input v-model="winnerWallet" placeholder="Enter your wallet" class="styled-input" />
      <button @click="submitWinnerInfo" class="styled-button">Submit</button>
    </div>
  </div>

  <div class="battle-container">
    <div class="opponent">
      <div
        v-for="(monster, index) in opponentMonsters"
        :key="monster.id"
        class="monster"
        :ref="'opponentMonster' + index"
        :class="{ 'dead-monster': monster.isDead }"
      >
      <div class="monster-container">
    <img :src="monster.image" alt="Monster" class="monster-img" />
    <img v-if="monster.bleed" :src="monster.bleedGif" alt="Bleed Effect" class="effect-img bleed-effect-img" />
    <img v-if="monster.stinkyEffect" :src="monster.stinkyEffect" alt="Stinky Effect" class="effect-img stinky-effect-img" />
</div>

        <p>{{ monster.name }} <span v-if="monster.voteCounters">- {{ monster.voteCounters }} VOTE(S)</span></p>
        <div class="health-bar">
          <div class="health" :style="healthBarStyle(monster)"></div>
          <div class="hp-text">{{ monster.hp }} / {{ monster.maxHp }}</div>
        </div>
      </div>
    </div>

    <div v-if="showFight" class="fight-text">FIGHT</div>
    <div v-if="!gameOver && lastAction" ref="lastActionText" class="last-action-text">
      {{ lastAction }}
    </div>

    <div class="player">
      <div
        v-for="(monster, index) in playerMonsters"
        :key="monster.id"
        class="monster"
        :ref="'playerMonster' + index"
        @click="monster.isDead ? null : selectMonster(index)"
      >
        <div class="monster-container">
          <img :src="monster.image" alt="Monster" class="monster-img" />
          <img v-if="monster.bleedEffect" :src="monster.bleedEffect" alt="Bleed Effect" class="effect-img bleed-effect-img" />
          <img v-if="monster.stinkyEffect" :src="monster.stinkyEffect" alt="Stinky Effect" class="effect-img" />
        </div>
        <p>
          {{ monster.name }}
          <span v-if="monster.stackCount > 0" class="stack-indicator">+{{ monster.stackCount }} STACKS</span>
        </p>
        <div class="health-bar">
          <div class="health" :style="healthBarStyle(monster)"></div>
          <div class="hp-text">{{ monster.hp }} / {{ monster.maxHp }}</div>
        </div>

        <div v-if="selectedMonsterIndex === index && !monster.isDead" class="attack-popout">
          <button
            v-for="attack in monster.attacks"
            :key="attack.name"
            :disabled="attack.currentCooldown > 0"
            :style="attack.currentCooldown > 0 ? 'color: grey;' : ''"
            @click="selectTarget(monster, attack, index)"
          >
            > {{ attack.name }}<span v-if="attack.currentCooldown > 0"> ({{ attack.currentCooldown }})</span>
          </button>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</template>


<script>
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../main.js'; // Adjust the path if needed

export default {
  props: {
    verifiedNFTs: {
      type: String,
      required: false,
      default: '[]'
    }
  },
  data() {
    return {
      playerMonsters: [],
      opponentMonsters: [],
      gameOver: false,
      gameOverMessage: "",
      showWinnerForm: false,
      winnerName: '',
      winnerWallet: '',
      showFireball: false,
      fireballStyle: {},
      fireballGif: '',
      showGrav: false,
      gravStyle: {},
      gravGif: '',
      currentAttack: null,
      currentWave: 1,
      currentAttackerIndex: null,
      targetPromptVisible: false,
      showFight: true,
      selectedMonsterIndex: null,
      statusEffects: {
        player: {},
        opponent: {}
      },
      lastAction: '',
      db: null, // Firebase Firestore database instance
    };
  },
  created() {
    console.log('Props verifiedNFTs:', this.verifiedNFTs);
    this.initializeGame();
  },
  methods: {
    initializeGame() {
      let verifiedNFTsSet;
      try {
        verifiedNFTsSet = new Set(JSON.parse(this.verifiedNFTs));
        console.log('Parsed verifiedNFTs:', verifiedNFTsSet);
      } catch (error) {
        console.error('Failed to parse verifiedNFTs:', error);
        verifiedNFTsSet = new Set();
      }
      this.startGame(verifiedNFTsSet);
    },
    startGame(verifiedNFTs) {
    console.log('Starting game with verifiedNFTs:', verifiedNFTs);
    if (!verifiedNFTs || verifiedNFTs.size === 0) {
        console.error('verifiedNFTs is undefined or empty');
        return;
    }
    this.currentWave = 1; // Reset to wave 1
    this.playerMonsters = this.generateMonsters(new Set(verifiedNFTs));
    this.generateWave();
},

generateWave() {
    this.opponentMonsters = []; // Clear the previous wave

    if (this.currentWave === 1) {
        console.log('Generating wave 1.');
        this.opponentMonsters = this.generateMonsters(new Set()); // Generate first wave of opponent monsters
    } else if (this.currentWave === 2) {
        console.log('Generating wave 2.');
        this.opponentMonsters = this.generateMonsters(new Set()); // Generate second wave of opponent monsters
    } else if (this.currentWave === 3) {
        console.log('Generating final boss.');
        this.opponentMonsters = [this.generateBoss()]; // Generate the final boss
    }
},

generateBoss() {
    return {
        id: 0,
        name: "FINAL BOSS",
        hp: 500,
        maxHp: 500,
        image: require('@/assets/img/boss.gif'),
        attacks: [
            { name: "MEGA SMASH", damage: 30 },
            { name: "ULTIMATE CODE", damage: 20 }
        ]
    };
},

checkForWin() {
    if (this.opponentMonsters.every(monster => monster.isDead)) {
        if (this.currentWave === 3) {
            this.gameOver = true;
            this.gameOverMessage = "You win!";
            this.showWinnerForm = true;
        } else {
            this.currentWave++;
            this.generateWave();
        }
    }
},

generateMonsters(verifiedNFTs) {
  console.log('Generating monsters with verifiedNFTs:', verifiedNFTs);
  const monsters = [];
  
  if (verifiedNFTs.has("MOJO")) {
    const hp = Math.floor(Math.random() * 100) + 100; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: "MOJO",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/mojo.gif'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "SMILE", damage: 10 }, // Increased damage, and it could apply a small debuff to the enemy
        { name: "CODE", damage: 0, cooldown: 10, currentCooldown: 0 } // Heal all player monsters by 20 HP, 10-turn cooldown
      ]
    });
  }

  if (verifiedNFTs.has("ROO")) {
    const hp = Math.floor(Math.random() * 100) + 120;
    monsters.push({
      id: monsters.length,
      name: "ROO",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/roo.png'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "PUNCH", damage: Math.floor(Math.random() * 15) + 20 },
        { name: "JUMP", damage: Math.floor(Math.random() * 25) + 35, cooldown: 2, currentCooldown: 0 } // Increased damage with 2-turn cooldown
      ]
    });
  }

  if (verifiedNFTs.has("CRASHPUNKS")) {
    const hp = Math.floor(Math.random() * 100) + 130; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: "CRASHPUNK",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/crashpunk.gif'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "LASER EYES", damage: Math.floor(Math.random() * 30) + 30, cooldown: 4, currentCooldown: 0 }, // Increased damage
        { name: "CRASH", damage: Math.floor(Math.random() * 25) + 20 } // Increased base damage with recoil effect
      ]
    });
  }

  if (verifiedNFTs.has("MEGAPONT")) {
    const hp = Math.floor(Math.random() * 100) + 140; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: "MEGAPONT APE",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/megapont.gif'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "THROW BANANA", damage: Math.floor(Math.random() * 15) + 10 }, // Increased damage
        { name: "THROW FECES", damage: Math.floor(Math.random() * 20) + 10, effect: "stinky" } // Increased base damage with stinky effect
      ]
    });
  }

  if (verifiedNFTs.has("BNS")) {
    const hp = Math.floor(Math.random() * 100) + 120; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: "BNS",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/bns.png'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "FIREBALL.BTC", damage: Math.floor(Math.random() * 15) + 10 }, // Increased damage
        { name: "GRAV.BTC", damage: Math.floor(Math.random() * 30) + 20 } // Increased damage
      ]
    });
  }

  if (verifiedNFTs.has("DAO")) {
    const hp = Math.floor(Math.random() * 100) + 100; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: "DAO",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/stacking.png'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "STACK", damage: 0 }, // No direct damage, but now adds a significant attack multiplier for the next attack
        { name: "VOTE", damage: 0 } // No direct damage, applies vote counters, and if 10 stacks are reached, instant faint
      ]
    });
  }

  if (verifiedNFTs.has("WELSH")) {
    const hp = Math.floor(Math.random() * 100) + 110; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: "WELSH",
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/welsh.gif'),
      stackCount: 0,
      stinkyEffect: null,
      attacks: [
        { name: "BITE", damage: Math.floor(Math.random() * 10) + 10, effect: "bleed" }, // Increased damage with bleed effect
        { name: "YAP", damage: 0, effect: "weaken" } // Reduces the enemy's attack by 50% for the next 3 turns
      ]
    });
  }

  // Ensure that the remaining slots are filled with default Sharkbod monsters
  while (monsters.length < 5) {
    const hp = Math.floor(Math.random() * 100) + 100; // Increase base HP
    monsters.push({
      id: monsters.length,
      name: `Sharkbod ${monsters.length + 1}`,
      hp: hp,
      maxHp: hp,
      image: require('@/assets/img/sharkbod.gif'),
      stackCount: 0,
      attacks: [
        { name: "SPLASH", damage: Math.floor(Math.random() * 15) + 10 }, // Increased damage
        { name: "SHAME", damage: Math.floor(Math.random() * 25) + 20 } // Increased damage
      ]
    });
  }

  console.log('Generated monsters:', monsters);
  return monsters;
},

healthBarStyle(monster) {
    const color = this.healthBarColor(monster.hp);
    const width = (monster.hp / monster.maxHp) * 100 + '%';
    return {
      width: width,
      backgroundColor: color,
    };
  },
  
  healthBarColor(hp) {
    if (hp <= 10) {
      return '#ff1000'; // Red
    } else if (hp <= 25) {
      return '#ff6900'; // Orange
    } else {
      return '#91c97b'; // Green (or whatever default color you want)
    }
  },

  async submitWinnerInfo() {
        if (!this.winnerName || !this.winnerWallet) {
            alert("Please enter both your name and wallet address.");
            return;
        }

        try {
            await addDoc(collection(db, 'winners'), {
                name: this.winnerName,
                wallet: this.winnerWallet,
                timestamp: new Date()
            });
            alert('Winner info submitted successfully!');
            this.showWinnerForm = false;
            this.startGame(); // Restart the game or any other logic you want here
        } catch (error) {
            console.error('Error submitting winner info:', error);
            alert('Failed to submit. Please try again.');
        }
},
    restartGame() {
      location.reload(); // Reload the page to reset the game
    },
    selectMonster(index) {
    if (this.selectedMonsterIndex === index) {
      // If the clicked monster is already selected, deselect it
      this.selectedMonsterIndex = null;
    } else {
      // Otherwise, select the clicked monster
      this.selectedMonsterIndex = index;
    }
  },
    applyStatusEffect(team, index, effect) {
  if (!this.statusEffects[team][index]) {
    this.statusEffects[team][index] = [];
  }
  if (!this.statusEffects[team][index].includes(effect)) {
    this.statusEffects[team][index].push(effect);
  }

  if (effect === 'weaken') {
    this.addWeakenEffect(team, index);
  } else if (effect === 'voteCounter') {
    this.addVoteCounterEffect(team, index);
  } else if (effect === 'stack') {
    this.addStackEffect(team, index);
  } else if (effect === 'stinky') {
    this.addStinkyEffect(team, index); // Ensure this line is here
  }
},

    addWeakenEffect(team, index) {
      const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
      targetMonster.weaken = true;
      targetMonster.damageReductionTurns = 3; // Lasts for 3 turns
      targetMonster.damageReduction = 0.5; // 50% less damage
    },

    addBleedEffect(team, index) {
    const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
    if (!targetMonster) return; // Ensure the target exists

    targetMonster.bleed = true;
    targetMonster.bleedDamage = 10; // Increase bleed damage to 10
    targetMonster.bleedTurns = 3; // Bleed effect lasts for 3 turns
    targetMonster.bleedGif = require('@/assets/img/bleed.gif'); // Set bleed gif

    console.log(`Bleed effect applied to ${targetMonster.name}. Damage: ${targetMonster.bleedDamage}, Turns: ${targetMonster.bleedTurns}`);
},

    addStinkyEffect(team, index) {
  const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
  targetMonster.stinky = true;
  targetMonster.stinkyDamage = 5;
  targetMonster.stinkyEffect = require('@/assets/img/darkflame.gif');
  console.log(`Applied stinky effect to ${targetMonster.name}`);

  if (index > 0) {
    const leftAdjacentMonster = team === 'player' ? this.playerMonsters[index - 1] : this.opponentMonsters[index - 1];
    leftAdjacentMonster.stinkyEffect = require('@/assets/img/darkflame.gif');
    console.log(`Applied stinky effect to ${leftAdjacentMonster.name}`);
  }

  if (index < (team === 'player' ? this.playerMonsters.length : this.opponentMonsters.length) - 1) {
    const rightAdjacentMonster = team === 'player' ? this.playerMonsters[index + 1] : this.opponentMonsters[index + 1];
    rightAdjacentMonster.stinkyEffect = require('@/assets/img/darkflame.gif');
    console.log(`Applied stinky effect to ${rightAdjacentMonster.name}`);
  }
},

    addSmileDebuffEffect(team, index) {
      const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
      targetMonster.smileDebuff = true;
      targetMonster.damageReduction = 0.5; // 50% less damage
    },

    addStackEffect(team, index) {
      const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
      targetMonster.stack = true;
      targetMonster.nextAttackMultiplier = 2;
    },


    checkOpponentMonsterHP(targetIndex) {
    const opponentMonster = this.opponentMonsters[targetIndex];
    if (opponentMonster) {
        if (opponentMonster.hp <= 0 && !opponentMonster.isDead) {
            opponentMonster.hp = 0; // Ensure HP is exactly 0
            opponentMonster.isDead = true; // Mark as dead
            console.log(`Opponent monster ${opponentMonster.name} is dead. HP: ${opponentMonster.hp}, isDead: ${opponentMonster.isDead}`);
        }
    }

    // Log the status of all monsters
    this.opponentMonsters.forEach((monster) => {
        console.log(`Monster ${monster.name} - HP: ${monster.hp}, isDead: ${monster.isDead}`);
    });

    // Check if all monsters in the current wave are defeated
    const allDefeated = this.opponentMonsters.every(monster => monster.isDead);
    console.log(`All monsters defeated: ${allDefeated}`);

    if (allDefeated) {
        if (this.currentWave < 3) {
            console.log(`Wave ${this.currentWave} completed. Generating next wave.`);
            this.currentWave++;
            this.generateWave();
        } else {
            console.log('All waves completed. Player wins!');
            this.gameOver = true;
            this.gameOverMessage = "You win!";
            this.showWinnerForm = true; // Show the form to enter the wallet address
        }
    }
},

checkPlayerMonsterHP(attackerIndex) {
    const playerMonster = this.playerMonsters[attackerIndex];
    if (playerMonster.hp <= 0) {
        playerMonster.hp = 0; // Ensure HP is exactly 0
        playerMonster.isDead = true; // Mark as dead
        playerMonster.image = require('@/assets/img/dead.gif'); // Change to dead gif
        playerMonster.attacks = []; // Clear attacks to prevent usage

        // Remove dead monsters from the battlefield
        this.playerMonsters.splice(attackerIndex, 1); // Optionally keep or remove this line
    }

    // If all player's monsters are dead
    if (this.playerMonsters.every(monster => monster.isDead)) {
        this.gameOver = true;
        this.gameOverMessage = "You lose!";
    }
},


    addConfusedEffect(team, index) {
      const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
      targetMonster.confused = true;
      targetMonster.confusedTurns = 1; // Confusion lasts for 1 turn
    },

    addVoteCounterEffect(team, index) {
    const targetMonster = team === 'player' ? this.playerMonsters[index] : this.opponentMonsters[index];
    if (!targetMonster.voteCounters) {
        targetMonster.voteCounters = 0;
    }
    targetMonster.voteCounters += 1;

    if (targetMonster.voteCounters >= 10) {
        targetMonster.hp = 0; // Monster faints if it reaches 10 counters
        this.checkOpponentMonsterHP(index); // Check opponent monster HP instead of direct removal
    }
},

    processStatusEffects() {
  const processEffects = (team) => {
    this[`${team}Monsters`].forEach((monster, index) => {
      if (monster.bleed) {
            console.log(`Processing bleed for ${monster.name}. Current HP: ${monster.hp}, Bleed Damage: ${monster.bleedDamage}`);
            monster.hp -= monster.bleedDamage;
            monster.bleedTurns -= 1;
            console.log(`${monster.name} HP after bleed: ${monster.hp}, Remaining Turns: ${monster.bleedTurns}`);
            if (monster.bleedTurns <= 0) {
                monster.bleed = false; // Remove bleed effect after turns are done
                console.log(`Bleed effect removed from ${monster.name}`);
            }
        }

      if (monster.onCooldown) {
        monster.onCooldown -= 1;
        if (monster.onCooldown <= 0) {
          delete monster.onCooldown;
        }
      }
      if (monster.voteCounters >= 10) {
    monster.hp = 0;  // Set HP to 0
    monster.isDead = true;  // Mark as dead
    monster.image = require('@/assets/img/dead.gif');  // Change to dead image
}

if (monster.stinky) {
                // Damage adjacent monsters
                if (this[`${team}Monsters`][index - 1]) {
                    const leftMonster = this[`${team}Monsters`][index - 1];
                    leftMonster.hp -= monster.stinkyDamage;
                    if (leftMonster.hp <= 0) {
                        leftMonster.hp = 0; // Ensure HP is not negative
                        leftMonster.isDead = true; // Mark as dead
                        leftMonster.image = require('@/assets/img/dead.gif'); // Apply dead.gif
                    }
                }
                if (this[`${team}Monsters`][index + 1]) {
                    const rightMonster = this[`${team}Monsters`][index + 1];
                    rightMonster.hp -= monster.stinkyDamage;
                    if (rightMonster.hp <= 0) {
                        rightMonster.hp = 0; // Ensure HP is not negative
                        rightMonster.isDead = true; // Mark as dead
                        rightMonster.image = require('@/assets/img/dead.gif'); // Apply dead.gif
                    }
                }
                
                // Ensure the monster with the stinky effect itself is also checked
                this.checkOpponentMonsterHP(index); 
            } else {
                // Clear the stinky effect if the status is no longer present
                monster.stinkyEffect = null;
            }

if (monster.weaken) {
    monster.damageReductionTurns -= 1;
    if (monster.damageReductionTurns <= 0) {
        delete monster.weaken;
    }
}

    });
  };
  processEffects('player');
  processEffects('opponent');
},

playerAttack(attacker, attack, attackerIndex, targetIndex = null) {
    if (!attack) {
        console.error("Attack is null or undefined");
        return;
    }

    const targetMonster = this.opponentMonsters.find(monster => monster.id === targetIndex);

    if (!targetMonster) {
        console.error("Target monster not found");
        return;
    }

    if (this.opponentMonsters.length > 0) {
        let damageDealt = Math.min(attack.damage || 0, targetMonster.hp); // Cap damage to target's remaining HP

        const targetMonsterElement = this.$refs[`opponentMonster${targetIndex}`][0];
        targetMonsterElement.classList.add('shake-animation');
        setTimeout(() => {
            targetMonsterElement.classList.remove('shake-animation');
        }, 500);

        switch (attack.name) {
            case 'STACK':
                this.playerMonsters.forEach(monster => {
                    monster.stackCount = (monster.stackCount || 0) + 1;
                });
                this.lastAction = `${attacker.name} used STACK. All friendly monsters gain a stack.`;
                break;

            case 'LASER EYES':
                if (attack.currentCooldown > 0) {
                    this.lastAction = `${attack.name} is on cooldown!`;
                } else {
                    attack.currentCooldown = attack.cooldown;
                    damageDealt = attack.damage * 2;
                    targetMonster.hp -= damageDealt;
                    this.lastAction = `${attacker.name} used LASER EYES on ${targetMonster.name}, dealing ${damageDealt} damage.`;
                    this.checkOpponentMonsterHP(targetIndex);
                }
                break;

            case 'THROW FECES':
                this.applyStatusEffect('opponent', targetIndex, 'stinky');
                damageDealt = attack.damage * 0.5;
                targetMonster.hp -= damageDealt;
                this.lastAction = `${attacker.name} used THROW FECES on ${targetMonster.name}, dealing ${damageDealt} damage.`;
                this.checkOpponentMonsterHP(targetIndex);
                break;

            case 'SHOOT':
            case 'PUNCH':
            case 'SMILE':
            case 'FIREBALL.BTC':
            case 'THROW BANANA':
                this.animateFireball(`playerMonster${attackerIndex}`, `opponentMonster${targetIndex}`, require('@/assets/img/fireball1.gif'));
                targetMonster.hp -= damageDealt;
                this.lastAction = `${attacker.name} used ${attack.name} on ${targetMonster.name}, dealing ${damageDealt} damage.`;
                this.checkOpponentMonsterHP(targetIndex);
                break;
            
                case 'JUMP': 
    if (attack.currentCooldown > 0) {
        this.lastAction = `${attack.name} is on cooldown!`;
    } else {
        attack.currentCooldown = 3; // Set cooldown to 2 turns
        damageDealt = Math.floor(attack.damage * 1.75); // Round down to the nearest integer
        targetMonster.hp -= damageDealt;

        // Animate and check for opponent's HP
        this.animateFireball(`playerMonster${attackerIndex}`, `opponentMonster${targetIndex}`, require('@/assets/img/sparks.gif'));
        this.checkOpponentMonsterHP(targetIndex);

        this.lastAction = `${attacker.name} used JUMP on ${targetMonster.name}, dealing ${damageDealt} damage.`;
    }
    break;

            

            case 'SHAME':
            case 'GRAV.BTC':
                this.animateGrav(`opponentMonster${targetIndex}`, require('@/assets/img/grav.gif'));
                targetMonster.hp -= damageDealt;
                this.lastAction = `${attacker.name} used ${attack.name} on ${targetMonster.name}, dealing ${damageDealt} damage.`;
                this.checkOpponentMonsterHP(targetIndex);
                break;
                case 'BITE':
    this.animateFireball(`playerMonster${attackerIndex}`, `opponentMonster${targetIndex}`, require('@/assets/img/fireball1.gif'));
    this.addBleedEffect('opponent', targetIndex); // Apply bleed effect here
    targetMonster.hp -= damageDealt;
    this.lastAction = `${attacker.name} used BITE on ${targetMonster.name}, dealing ${damageDealt} damage and causing it to bleed.`;
    this.checkOpponentMonsterHP(targetIndex);
    break;

            case 'YAP':
                this.animateGrav(`opponentMonster${targetIndex}`, require('@/assets/img/grav.gif'));
                this.opponentMonsters.forEach(monster => {
                    if (!monster.isDead) {
                        monster.hp -= 10;
                        this.applyStatusEffect('opponent', this.opponentMonsters.indexOf(monster), 'weaken');  // Apply weaken effect
                        this.checkOpponentMonsterHP(this.opponentMonsters.indexOf(monster));
                    }
                });
                this.lastAction = `${attacker.name} used YAP, dealing 10 damage to all living enemies and applying weaken.`;
                break;

                case 'CRASH': {
    this.animateFireball(`playerMonster${attackerIndex}`, `opponentMonster${targetIndex}`, require('@/assets/img/fireball1.gif'));

    // Apply damage to the opponent
    targetMonster.hp -= damageDealt;
    
    // Check if the target monster is dead
    if (targetMonster.hp <= 0) {
        targetMonster.hp = 0; // Ensure HP is exactly 0
        targetMonster.isDead = true; // Mark as dead
        targetMonster.image = require('@/assets/img/dead.gif'); // Change to dead gif
    }

    this.checkOpponentMonsterHP(targetIndex);

    // Apply recoil damage to the attacker
    const recoilDamage = Math.ceil(damageDealt * 0.3); // Round up recoil damage
    attacker.hp -= recoilDamage;
    this.checkPlayerMonsterHP(attackerIndex); // Check if the attacker fainted due to recoil

    // Update the lastAction text with recoil information
    this.lastAction = `${attacker.name} used ${attack.name} on ${targetMonster.name}, dealing ${damageDealt} damage. ${attacker.name} took ${recoilDamage} recoil damage.`;

    // No return here, so the turn proceeds as usual
    break; // Continue to the rest of the logic for the turn
}


            case 'CODE':
                if (attack.currentCooldown > 0) {
                    this.lastAction = `${attack.name} is on cooldown!`;
                } else {
                    attack.currentCooldown = 10; // Set cooldown to 10 turns
                    this.playerMonsters.forEach(monster => {
                        monster.hp = Math.min(monster.maxHp, monster.hp + 10); // Heal by 10 but do not exceed max HP
                    });
                    this.lastAction = `${attacker.name} used CODE. All friendly monsters are healed by 10 HP.`;
                }
                break;

            case 'VOTE':
                this.applyStatusEffect('opponent', targetIndex, 'voteCounter');
                if (targetMonster.voteCounters >= 10) {
                    targetMonster.hp = 0; // Set HP to 0 to trigger fainting
                    targetMonster.isDead = true;
                    targetMonster.image = require('@/assets/img/dead.gif'); // Change to dead image
                    this.lastAction = `${attacker.name} used ${attack.name} on ${targetMonster.name}, reducing its health to 0.`;
                    this.checkOpponentMonsterHP(targetIndex);
                    return;
                }
                break;

            default:
                console.error('Unhandled attack type:', attack.name);
                break;
        }

        // Check if the target is dead and update its state
        if (targetMonster.hp <= 0) {
            targetMonster.isDead = true;
            targetMonster.hp = 0; // Ensure HP is set to 0
            targetMonster.image = require('@/assets/img/dead.gif');
        }

        // Update the lastAction text
        this.$nextTick(() => {
            const lastActionElement = document.querySelector('.last-action-text');
            if (lastActionElement) {
                lastActionElement.textContent = this.lastAction; // Directly set the text
            }
        });

        // Check if the game should end or proceed to the next wave
        this.checkForWin();

        // Delay for opponent's turn
        setTimeout(() => {
            this.decrementCooldowns();
            this.processStatusEffects();
            this.opponentAttack(); // Trigger the opponent's turn
        }, 1000);
    }
},

completeJumpAttack(attacker, attackerIndex, targetIndex) {
    this.animateHitWithShake(targetIndex, 'smoke');
    this.opponentMonsters[targetIndex].hp -= attacker.attacks.find(a => a.name === 'JUMP').damage * 1.5; // 50% more damage
    this.checkOpponentMonsterHP(targetIndex);
    attacker.untargetable = false; // Make the monster targetable again

    if (this.opponentMonsters.length === 0) {
        this.gameOver = true;
        this.gameOverMessage = "You win!";
    }
},

decrementCooldowns() {
    this.playerMonsters.forEach((monster) => {
        if (monster && monster.attacks) {
            monster.attacks.forEach((attack) => {
                if (attack.currentCooldown > 0) {
                    attack.currentCooldown -= 1;
                }
            });
        }
    });

    this.opponentMonsters.forEach((monster) => {
        if (monster && monster.attacks) {
            monster.attacks.forEach((attack) => {
                if (attack.currentCooldown > 0) {
                    attack.currentCooldown -= 1;
                }
            });
        }
    });
},


opponentAttack() {
    if (this.playerMonsters.length > 0) {
        const attacker = this.opponentMonsters[0];  // Selects the first opponent monster to attack
        const targetIndex = Math.floor(Math.random() * this.playerMonsters.length);  // Randomly select a player's monster to attack
        const attack = attacker.attacks[Math.floor(Math.random() * attacker.attacks.length)];  // Randomly select an attack

        let damageDealt = attack.damage || 0;

        const targetMonsterElement = this.$refs[`playerMonster${targetIndex}`][0];
        targetMonsterElement.classList.add('shake-animation');
        setTimeout(() => {
            targetMonsterElement.classList.remove('shake-animation');
        }, 500);

        if (attack.name === 'SPLASH') {
            this.animateGrav(`playerMonster${targetIndex}`, require('@/assets/img/water.gif'));
        } else if (attack.name === 'SHAME') {
            this.animateGrav(`playerMonster${targetIndex}`, require('@/assets/img/grav.gif'));
        }

        const playerMonster = this.playerMonsters[targetIndex];
        playerMonster.hp -= damageDealt;

        if (playerMonster.hp <= 0) {
    // Mark the player monster as dead and change its image
    playerMonster.isDead = true;
    playerMonster.hp = 0;  // Ensure HP is set to 0
    playerMonster.image = require('@/assets/img/dead.gif');
}


        // Ensure the monster still exists before trying to access it
        if (this.playerMonsters.every(monster => monster.isDead)) {
    this.gameOver = true;
    this.gameOverMessage = "You lose!";
    return;
}


        if (this.playerMonsters[targetIndex]) {
            // Continue processing if the monster still exists
            this.lastAction = `${attacker.name} used ${attack.name} on ${this.playerMonsters[targetIndex].name}, dealing ${damageDealt} damage.`;
        }
    }
},


    animateEvade(attackerIndex, gifSrc) {
      const attackerElem = this.$refs[`playerMonster${attackerIndex}`][0];
      attackerElem.style.visibility = 'hidden';
      this.$nextTick(() => {
        setTimeout(() => {
          attackerElem.style.visibility = 'visible';
          this.animateGrav(`playerMonster${attackerIndex}`, require(`@/assets/img/${gifSrc}.png`));
        }, 1000); // Timing for reappearing
      });
    },

    animateHitWithShake(targetIndex, gifSrc) {
      const targetElem = this.$refs[`opponentMonster${targetIndex}`][0];
      targetElem.classList.add('shake-animation');
      this.animateGrav(`opponentMonster${targetIndex}`, require(`@/assets/img/${gifSrc}.png`));
      setTimeout(() => {
        targetElem.classList.remove('shake-animation');
      }, 500); // Adjust timing as necessary
    },

    animateFlare(attackerIndex, targetIndex, gifSrc) {
      this.animateGrav(`playerMonster${attackerIndex}`, require(`@/assets/img/${gifSrc}.gif`));
      setTimeout(() => {
        this.animateGrav(`opponentMonster${targetIndex}`, require(`@/assets/img/${gifSrc}.gif`));
      }, 500);
    },

    applyStackEffect(attacker) {
  this.playerMonsters.forEach(monster => {
    monster.stackCount = (monster.stackCount || 0) + 1;
    monster.nextAttackMultiplier = 1 + Math.floor(monster.stackCount / 2) * 0.5; // +5% per 2 stacks
  });

  // Update the lastAction text with the STACK action details
  this.lastAction = `${attacker.name} used STACK. All friendly monsters now have ${attacker.stackCount} stacks.`;

  // Trigger the next turn after applying STACK
  this.$nextTick(() => {
    const lastActionElement = document.querySelector('.last-action-text');
    if (lastActionElement) {
      lastActionElement.style.animation = 'none'; // Reset animation
      lastActionElement.style.borderRight = 'none'; // Reset border to avoid caret before animation
      lastActionElement.offsetHeight; // Trigger reflow
      lastActionElement.classList.remove('typing'); // Ensure previous typing class is removed
      lastActionElement.style.width = '0'; // Start at 0 width for typing effect
      lastActionElement.classList.add('typing'); // Reapply typing class with animation
    }
  });

  // Proceed to opponent's turn or other actions
  setTimeout(() => {
    this.decrementCooldowns();
    this.processStatusEffects();
    this.opponentAttack();
  }, 2500); // Adjust timing as needed
},

selectTarget(monster, attack, index) {
    if (monster.isDead) {
        console.error("This monster is dead and cannot attack.");
        return;
    }

    this.showFight = false;

    if (!attack) {
        console.error("Attack is null or undefined");
        return;
    }

    console.log(`Selecting target for attack: ${attack.name}`);
    this.currentAttack = attack;
    this.currentAttackerIndex = index;

    if (attack.name === 'STACK' || attack.name === 'CODE') {
        this.applyStackOrCodeEffect(monster, attack, index);
        return;
    }

    // Highlight available targets
    this.opponentMonsters.forEach((opponentMonster, i) => {
        if (!opponentMonster.isDead) {
            const monsterElement = this.$refs[`opponentMonster${i}`]?.[0];
            if (monsterElement) {
                monsterElement.style.border = '2px solid #ff6900';
                monsterElement.addEventListener('click', () => this.targetMonster(i, 'opponent'));
            }
        }
    });
},
    
targetMonster(index) {
    this.targetPromptVisible = false;

    // Remove event listeners and highlights from opponent monsters
    this.opponentMonsters.forEach((monster, i) => {
        const monsterElement = this.$refs[`opponentMonster${i}`]?.[0];
        if (monsterElement) {
            monsterElement.onclick = null; // Clear the click event
            monsterElement.style.border = ''; // Remove highlight border
        }
    });

    const attack = this.currentAttack;
    const attackerIndex = this.currentAttackerIndex;
    const attacker = this.playerMonsters[attackerIndex];
    if (!attack) {
        console.error("Attack is null or undefined");
        return;
    }

    // Ensure the index is valid and the target is alive
    if (index < 0 || index >= this.opponentMonsters.length || this.opponentMonsters[index].isDead) {
        console.error("Invalid target or target is already defeated");
        return;
    }

    // Now we add the check to see if the selected monster is still valid before we proceed
    if (!this.opponentMonsters[index] || this.opponentMonsters[index].hp <= 0) {
        console.error("Attempting to target a monster that is dead or does not exist.");
        return;
    }

    this.playerAttack(attacker, attack, attackerIndex, index);
    this.currentAttack = null;
    this.currentAttackerIndex = null;
},

animateFireball(fromRef, toRef, gifSrc) {
    const fromElem = this.$refs[fromRef]?.[0];
    const toElem = this.$refs[toRef]?.[0];

    if (!fromElem || !toElem) {
        console.error('Animation elements not found:', fromRef, toRef);
        return;
    }

    const fromRect = fromElem.getBoundingClientRect();
    const toRect = toElem.getBoundingClientRect();

    console.log('From Element Rect:', fromRect);
    console.log('To Element Rect:', toRect);

    const startX = fromRect.left + fromRect.width / 2 - 40;
    const startY = fromRect.top + fromRect.height / 2 - 40;
    const endX = toRect.left + toRect.width / 2 - 40;
    const endY = toRect.top + toRect.height / 2 - 40;

    console.log('StartX, StartY:', startX, startY);
    console.log('EndX, EndY:', endX, endY);

    this.fireballStyle = {
        top: `${startY}px`,
        left: `${startX}px`,
        width: '80px',
        height: '80px',
        transform: `translate(${endX - startX}px, ${endY - startY}px)`,
        visibility: 'visible',
    };

    this.showFireball = true;
    this.fireballGif = gifSrc;

    this.$nextTick(() => {
        setTimeout(() => {
            this.showFireball = false;
            this.fireballStyle = {};
        }, 1000); // Adjust the duration as necessary
    });
},

applyStackOrCodeEffect(attacker, attack) {
    if (attack.name === 'STACK') {
        this.playerMonsters.forEach(monster => {
            monster.stackCount = (monster.stackCount || 0) + 1;
        });
        this.lastAction = `${attacker.name} used STACK. All friendly monsters gain a stack.`;
    } else if (attack.name === 'CODE') {
        if (attack.currentCooldown > 0) {
            this.lastAction = `${attack.name} is on cooldown!`;
        } else {
            attack.currentCooldown = 10; // Set cooldown to 10 turns
            this.playerMonsters.forEach(monster => {
                monster.hp = Math.min(monster.maxHp, monster.hp + 10); // Heal by 10 but do not exceed max HP
            });
            this.lastAction = `${attacker.name} used CODE. All friendly monsters are healed by 10 HP.`;
        }
    }

    // Automatically proceed to opponent's turn after applying the effect
    this.$nextTick(() => {
        const lastActionElement = document.querySelector('.last-action-text');
        if (lastActionElement) {
            lastActionElement.textContent = this.lastAction; // Directly set the text
        }
    });

    // Proceed to opponent's turn after a delay
    setTimeout(() => {
        this.decrementCooldowns();
        this.processStatusEffects();
        this.opponentAttack(); // Trigger the opponent's turn
    }, 1000);
},


animateGrav(toRef, gifSrc) {
    const toElem = this.$refs[toRef]?.[0];

    if (!toElem) {
        console.error('Animation element not found:', toRef);
        return;
    }

    const toRect = toElem.getBoundingClientRect();

    console.log('To Element Rect:', toRect);

    const endX = toRect.left + toRect.width / 2 - 50; // Adjust size as needed
    const endY = toRect.top + toRect.height / 2 - 50;

    console.log('EndX, EndY:', endX, endY);

    this.gravStyle = {
        top: `${endY}px`,
        left: `${endX}px`,
        width: '100px',
        height: '100px',
        visibility: 'visible',
    };

    this.showGrav = true;
    this.gravGif = gifSrc;

    setTimeout(() => {
        this.showGrav = false;
        this.gravStyle = {};
    }, 1000); // Adjust the duration as necessary
},


  },
  checkForWin() {
      if (this.opponentMonsters.every(monster => monster.hp <= 0)) {
        this.gameOver = true;
        this.gameOverMessage = "You win!";
        this.showWinnerForm = true; // Automatically show the winner form
      }
    },
  mounted() {
    this.startGame();
  },
};
</script>

<style scoped>
.battle-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 10px; /* Optional padding to prevent content from touching edges */
}

.winner-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ff6900;
  font-size: 24px;
}

.winner-form-container input {
  display: block;
  margin: 10px auto;
  padding: 10px;
  font-size: 16px;
  width: 80%;
}

.winner-form-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff6900;
  border: none;
  color: white;
  cursor: pointer;
}

.winner-form-container button:hover {
  background-color: #ff8a00;
}

.opponent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2em; /* Add margin to space out from the fight text */
}

.player {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3em; /* Increase margin to lower the player monsters */
}


.monster-container {
  position: relative;
  width: 15vw; /* Width relative to viewport width for responsive scaling */
  height: 15vw; /* Height relative to viewport width for responsive scaling */
  margin: 5px;
}

@media (max-width: 768px) {
  .monster-container {
    width: 20vw; /* Adjust size for tablets */
    height: 20vw;
  }
}

@media (max-width: 576px) {
  .monster-container {
    width: 25vw; /* Adjust size for mobile */
    height: 25vw;
  }
}

.attack-popout {
  position: absolute;
  left: 50%; /* Center the pop-out horizontally over the monster */
  top: -20%; /* Move the pop-out above the monster container */
  transform: translateX(-50%); /* Adjust to ensure the pop-out is centered */
  background-color: black;
  border: 2px solid #ff6900;
  padding: 0.5em;
  width: 14em;
  height: 3em;
  z-index: 10;
}

.attack-popout button {
  display: block;
  color: #ff6900;
  background: none;
  border: none;
  font-family: 'C64 Pro Mono Local', verdana, helvetica, sans-serif;
  font-size: 0.75em;
  padding: 5px 0;
  text-align: center;
}

.attack-popout button:hover {
  text-shadow: 0 0 5px #ff7d20;
}
.effect-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure the effect image doesn't interfere with user interaction */
}

.stinky-effect-img {
  /* This ensures that the stinky effect gif is above the monster image */
  z-index: 10; /* Ensure this is higher than the other elements */
}

.effect-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Ensure the effect image doesn't interfere with user interaction */
    z-index: 10; /* Ensure the effect image is on top */
}

.bleed-effect-img {
    z-index: 20; /* Ensure this is higher than other elements if needed */
}


.monster {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid green;
  padding: 10px;
  background-color: black;
  position: relative; /* Make sure the popout is relative to the monster container */
  cursor: pointer;
}

.monster-img {
  width: 100%; /* Make the image take up the full width of the container */
  height: auto; /* Maintain aspect ratio */
}


button {
  background: none;
  border: none;
  color: #ff6900; /* Example text color for verified state */
  font-family: 'C64 Pro Mono Local', verdana, helvetica, sans-serif;
  font-size: 0.75em;
  padding: 5px;
  cursor: pointer;
}

button:hover {
  text-shadow: 0 0 5px #ff6900;
}

button:focus {
  outline: none;
}


.health-bar {
  width: 150px; /* Fixed width for all health bars */
  background-color: #e0e0e0;
  border: 1px solid #000;
  height: 20px;
  margin-bottom: 10px;
  position: relative;
}

.health {
  height: 100%;
  background-color: #91c97b;
  position: absolute;
  top: 0;
  left: 0;
}

.hp-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
  color: #000;
  z-index: 1;
  pointer-events: none; 
}


.fireball {
    position: absolute;
    width: 80px;
    height: 80px;
    visibility: hidden;
    transition: transform 1s ease-in-out;
    transform-origin: center;
    z-index: 10;
}

.grav {
    position: absolute;
    width: 100px;
    height: 100px;
    visibility: hidden;
    z-index: 10;
}





.target-prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: #ff6900; /* Example text color for verified state */
}

.fight-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  color: #ff6900;
  text-shadow: 
    0 0 2px #923d00, 
    0 0 4px #923d00, 
    0 0 6px #923d00, 
    0 0 8px #923d00, 
    0 0 10px #923d00;
  text-align: center;
}

.overlay {
  position: fixed; /* Ensure the overlay stays in place while scrolling */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/img/scanlines.png);
  background-repeat: repeat; /* Make sure the image repeats */
  background-size: contain; /* Adjust this to fit the image properly */
  opacity: 0.3;
  pointer-events: none; /* Ensure the overlay doesn't interfere with user interaction */
  z-index: 1; /* Make sure it's behind the content */
}



.shake-animation {
  animation: shake 0.5s;
}



@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(10px); }
  75% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange; }
}

.last-action-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: #ff6900;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  border-right: 2px solid orange; /* Caret effect */
  animation: blink-caret 0.75s step-end infinite; /* Initial caret animation */
}

.typing {
  display: inline-block;
  width: 0; /* Start with no width */
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid orange; /* Caret effect */
  animation: typing 2.5s steps(30, end) forwards, blink-caret 0.75s step-end infinite;
}

.dead-monster .monster-img {
    opacity: 0.5; /* Make the dead monster appear faded */
}

.dead-monster {
    pointer-events: none; /* Prevent interaction with dead monsters */
}
.victory-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Use fixed positioning to overlay the entire game screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ff6900;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000; /* Ensure it stays above all other content */
  background-color: rgba(0, 0, 0, 0.85); /* Optional background to ensure text readability */
}


.win-message {
  font-size: 2em; /* Make the "YOU WIN" message larger */
  margin-bottom: 1em; /* Add space below the "YOU WIN" message */
}

.win-subtext {
  font-size: 1em; /* Subtext should be smaller */
  margin-bottom: 1empx; /* Add space below the subtext */
}

.styled-input {
  background: none;
  border: 2px solid #ff6900;
  color: #ff6900;
  font-family: 'C64 Pro Mono Local', verdana, helvetica, sans-serif;
  font-size: 1em;
  padding: 1em;
  width: 80%; /* Adjust as needed */
  margin-bottom: 10px; /* Add space below each input field */
}

.styled-input::placeholder {
  color: #ff6900;
  opacity: 0.6;
}

.styled-button {
  background: none;
  border: 2px solid #ff6900;
  color: #ff6900;
  font-family: 'C64 Pro Mono Local', verdana, helvetica, sans-serif;
  font-size: 1em;
  padding: 1em 1em;
  cursor: pointer;
  margin-top: 20px; /* Add space above the button */
}

.styled-button:hover {
  text-shadow: 0 0 5px #ff6900;
}

.styled-button:focus {
  outline: none;
  text-shadow: 0 0 5px #ff6900;
}

</style>
