<template>
  <div id="window">
    <p class="c64pm px16 glowing-text" style="text-align: center;">
      SHARKBODS TERMINAL 4-X
    </p>
    <img src="../assets/img/s.gif" class="centered-gif">
    <p class="c64pm px16 glowing-text">&nbsp;</p>
    <p class="c64pm px16 glowing-text" style="text-align: center;">
      INPUT COMMANDS
    </p>
    <div v-for="(entry, index) in commandsHistory" :key="index">
      <span :class="['c64pm px16', entry.isOrange ? 'glowing-text-verified' : 'glowing-text']">{{ entry.text }}</span>
    </div>

    <!-- Show the wallet input box if showWalletInput is true -->
    <div v-if="showWalletInput" class="wallet-input">
      <input type="text" v-model="walletAddress" class="c64pm px16 glowing-text input-box" placeholder="Enter Wallet Address">
      <button @click="submitWalletAddress" class="c64pm px16 glowing-text-verified">ADD WALLET TO WHITELIST</button>
    </div>

    <!-- Show command options otherwise -->
    <div v-else-if="commandOptions.length > 0" class="options">
      <span v-for="(option, index) in commandOptions" :key="index" class="c64pm px16 glowing-text-verified command-option" @click="handleCommandSelection(option)">
        > {{ option.text }}
      </span>
    </div>
    <div class="overlay"></div>
  </div>
</template>


<script>
import { showConnect } from '@stacks/connect';
import axios from 'axios';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore'; // Ensure you're importing from the correct Firebase package
import { db } from '../main.js'; // Adjust the path accordingly

export default {
  name: 'HomeView',
  data() {
    return {
      commandsHistory: [], // This will store the history of commands and responses
      commandOptions: [], // Will initialize after printing first terminal lines
      isConnected: false,
      isVerified: false,
      connectedWalletAddress: '',
      verifiedNFTs: new Set(),
      showWalletInput: false, // Control the visibility of the wallet input box
      walletAddress: '', // Model for the wallet address input
    };
  },
  methods: {
    initializeTerminal() {
      // Print initial terminal output
      this.addOutput('WELCOME, FRIEND');
      this.addOutput('SHARKBODS ARE COMING TO STX');
      this.addOutput('ENTER A COMMAND OR TYPE HELP');
      this.presentNextOptions([
        { text: 'ABOUT SHARKBODS', command: 'about' },
        { text: 'CONNECT WALLET', command: 'connect' },
        { text: 'BATTLE TEST', command: 'battleTest' }
      ]);
    },
    handleCommandSelection(option) {
      // Keep the selected command in the history with the correct color
      this.commandsHistory.push({ text: `> ${option.text}`, isOrange: true });

      // Execute the command logic
      switch (option.command) {
        case 'about':
          this.addOutput("SHARKBODS IS A COLLECTION OF SHARKS WITH DAD BODS...");
          this.presentNextOptions([
            { text: 'CONNECT WALLET', command: 'connect' },
            { text: 'CLEAR SCREEN', command: 'clear' },
          ]);
          break;
        case 'connect':
          this.connectWallet();
          break;
        case 'clear':
          this.clearScreen();
          break;
        case 'verify':
          this.verifyNFT();
          break;
          case 'whitelist':
      if (this.isVerified) {
        this.addOutput('PLEASE ENTER YOUR WALLET ADDRESS:');
        this.commandOptions = []; // Clear options to display the input box
        this.showWalletInput = true; // Show the input box
      } else {
        this.addOutput('PLEASE VERIFY YOUR ASSETS BY CONNECTING A WALLET');
      }
      break;
        case 'battle':
          if (this.isVerified) {
            const verifiedNFTsArray = Array.from(this.verifiedNFTs);
            console.log('Navigating to battler with params:', verifiedNFTsArray);
            this.$router.push({ name: 'battler', query: { verifiedNFTs: JSON.stringify(verifiedNFTsArray) } });
          } else {
            this.addOutput('VERIFY FIRST');
          }
          break;
          case 'battleTest': // Handle the Battle Test case
      this.initiateBattle(); // This is your battle test function
      break;
        default:
          this.addOutput('UNKNOWN COMMAND');
          this.presentNextOptions([
            { text: 'ABOUT SHARKBODS', command: 'about' },
            { text: 'CONNECT WALLET', command: 'connect' },
          ]);
          break;
      }
    },
    presentNextOptions(options) {
      this.commandsHistory.push({ text: '', isOrange: false }); // Blank line before new options
      this.commandOptions = options;
    },
    addOutput(text) {
      this.commandsHistory.push({ text: text, isOrange: false });
    },
    clearScreen() {
      this.commandsHistory = [];
      this.initializeTerminal();
    },
    async connectWallet() {
  this.commandOptions = []; // Clear options to prevent premature showing
  showConnect({
    appDetails: {
      name: "SHARKBODS",
      icon: "https://example.com/icon.png",
    },
    redirectTo: "/",
    onFinish: async (data) => {
      console.log("Auth response received:", data);
      if (!data.authResponse) {
        this.addOutput('Failed to connect wallet: No auth response');
        return;
      }

      const payload = data.authResponsePayload;
      if (!payload) {
        this.addOutput('Failed to connect wallet: No payload in auth response');
        return;
      }

      const address = payload.profile.stxAddress.mainnet;
      if (!address) {
        this.addOutput('Failed to connect wallet: No address in payload');
        return;
      }

      this.isConnected = true;
      this.connectedWalletAddress = address;
      this.addOutput('CONNECTED. PLEASE VERIFY YOUR ASSETS.');

      // Check if the wallet has already been submitted
      const alreadySubmitted = await this.isUserAlreadyVerified(this.connectedWalletAddress);

      // Present the verify assets option regardless of whitelist status
      const options = [
        { text: 'VERIFY ASSETS', command: 'verify' },
        { text: 'CLEAR SCREEN', command: 'clear' },
      ];

      // Only add the "ADD WALLET TO WL" option if not already submitted
      if (!alreadySubmitted) {
        options.push({ text: 'ADD WALLET TO WL', command: 'whitelist' });
      } else {
        options.push({ text: 'BATTLE', command: 'battle' });
      }

      this.presentNextOptions(options);
    },
  });
}
,
initiateBattle() {
  const adminVerifiedNFTs = ['MOJO', 'MEGAPONT', 'ROO', 'CRASHPUNKS', 'DAO'];
  console.log('Navigating to battler with admin params:', adminVerifiedNFTs);
  this.$router.push({ name: 'battler', query: { verifiedNFTs: JSON.stringify(adminVerifiedNFTs) } });
},
async verifyNFT() {
  if (!this.isConnected) {
    this.addOutput('CONNECT A WALLET');
    return;
  }

  try {
    let assets = [];
    let offset = 0;
    const limit = 50;
    let moreResults = true;

    while (moreResults) {
      const response = await axios.get(`https://stacks-node-api.mainnet.stacks.co/extended/v1/address/${this.connectedWalletAddress}/assets`, {
        params: { limit, offset }
      });

      if (response.data.results && response.data.results.length > 0) {
        assets = assets.concat(response.data.results);
        offset += limit;
      } else {
        moreResults = false;
      }
    }

    this.verifiedNFTs.clear();
    const verifiedMessages = new Set();
    let ownsNFT = false;

    assets.forEach(asset => {
      const assetIdentifier = asset.asset ? asset.asset.asset_id : asset.asset_id;

      if (assetIdentifier === 'SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S.mojo::mojo') {
        verifiedMessages.add('MOJO HOLDER');
        this.verifiedNFTs.add('MOJO');
        ownsNFT = true;
      } else if (assetIdentifier === 'SP2C1WREHGM75C7TGFAEJPFKTFTEGZKF6DFT6E2GE.kangaroo::kangaroo') {
        verifiedMessages.add('$ROO HOLDER');
        this.verifiedNFTs.add('ROO');
        ownsNFT = true;
      } else if (assetIdentifier === 'SP3NE50GEXFG9SZGTT51P40X2CKYSZ5CC4ZTZ7A2G.welshcorgicoin-token::welshcorgicoin') {
        verifiedMessages.add('$WELSH HOLDER');
        this.verifiedNFTs.add('WELSH');
        ownsNFT = true;
      }
    });

    if (ownsNFT) {
      this.addOutput('USER VERIFIED:');
      verifiedMessages.forEach(message => {
        this.addOutput(message);
      });
      this.isVerified = true;

      const alreadySubmitted = await this.isUserAlreadyVerified(this.connectedWalletAddress);

      if (alreadySubmitted) {
        this.presentNextOptions([
          { text: 'BATTLE', command: 'battle' },
          { text: 'CLEAR SCREEN', command: 'clear' },
        ]);
      } else {
        this.presentNextOptions([
          { text: 'BATTLE', command: 'battle' },
          { text: 'ADD WALLET TO WL', command: 'whitelist' },
          { text: 'CLEAR SCREEN', command: 'clear' },
        ]);
      }

    } else {
      this.isVerified = false;
      this.addOutput('VERIFICATION FAILED');
      this.presentNextOptions([
        { text: 'CLEAR SCREEN', command: 'clear' },
      ]);
    }
  } catch (error) {
    console.error("Failed to verify NFT:", error);
    this.addOutput('VERIFICATION FAILED');
    this.presentNextOptions([
      { text: 'CLEAR SCREEN', command: 'clear' },
    ]);
  }
}
,
    submitWalletAddress() {
    if (this.walletAddress.trim() === '') {
      this.addOutput('WALLET ADDRESS CANNOT BE EMPTY');
    } else {
      this.addWalletToWhitelist(this.walletAddress.trim());
      this.walletAddress = ''; // Clear the input
      this.showWalletInput = false; // Hide the input box after submission
    }
  },
  async addWalletToWhitelist(walletAddress) {
    try {
      const userVerifiedWallet = this.connectedWalletAddress;

      const alreadySubmitted = await this.isUserAlreadyVerified(userVerifiedWallet);

      if (alreadySubmitted) {
        this.addOutput('YOU HAVE ALREADY SUBMITTED A WALLET ADDRESS');
        this.presentNextOptions([
        { text: 'BATTLE', command: 'battle' },
        { text: 'CLEAR SCREEN', command: 'clear' },
      ]);
      }

      const walletsRef = collection(db, 'whitelist');
      await addDoc(walletsRef, {
        verifiedwallet: userVerifiedWallet,
        walletaddress: walletAddress,
      });

      this.addOutput(`WALLET ADDRESS ${walletAddress} ADDED TO WHITELIST`);

      // Present new options after successfully adding the wallet
      this.presentNextOptions([
        { text: 'BATTLE', command: 'battle' },
        { text: 'CLEAR SCREEN', command: 'clear' },
      ]);

    } catch (error) {
      console.error('Error adding wallet to whitelist:', error);
      this.addOutput('FAILED TO ADD WALLET TO WHITELIST');
    }
  },



  async isUserAlreadyVerified(verifiedWallet) {
    try {
      // Query the whitelist collection to check if the verified wallet has already submitted a wallet address
      const whitelistRef = collection(db, 'whitelist');
      const q = query(whitelistRef, where('verifiedwallet', '==', verifiedWallet));
      const querySnapshot = await getDocs(q);

      return !querySnapshot.empty; // Return true if the wallet has already submitted, false otherwise
    } catch (error) {
      console.error('Error checking if wallet is already verified:', error);
      return false; // In case of error, assume the wallet has not been submitted
    }
  }},
  mounted() {
    this.initializeTerminal(); // Initialize the terminal output on mount
  }
};
</script>

<style scoped>
@font-face {
  font-family: "C64 Pro Mono Local";
  src: url("../assets/fonts/C64_Pro_Mono-STYLE.woff2") format("woff2");
}

html {
  border: 80px solid rgb(11, 12, 11);
  padding: 40px;
  margin: 0px;
  min-height: 100vh;
  height: 100vh;
  box-sizing: border-box;
}

body.verified {
  background-color: #f0f8ff;
  color: #ff6900;
}

body.verified .c64pm {
  color: #ff6900;
}

body.verified #window {
  border-color: #ff6900;
}

#window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar { display: none; }
}

.hidden-input {
  position: absolute;
  left: 0;
  width: 1px;
  height: 1px;
  border: none;
  opacity: 0;
}

.overlay {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 10000;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  background-image: url(../assets/img/scanlines.png);
  opacity: 0.4;
  pointer-events: none;
}

.glowing-text {
  color: #48ff00;
  text-shadow: 
    0 0 1px #415a37, 
    0 0 2px #415a37, 
    0 0 3px #415a37, 
    0 0 4px #415a37, 
    0 0 5px #415a37;
}

.glowing-text-verified {
  color: #ff6900;
  text-shadow: 
    0 0 1px #923d00, 
    0 0 2px #923d00, 
    0 0 3px #923d00, 
    0 0 4px #923d00, 
    0 0 5px #923d00;
}

.c64pm {
  font: normal 16pt/24px "C64 Pro Mono Local", verdana, helvetica, sans-serif;
  letter-spacing: 0px;
  padding: 0;
  margin: 0;
}

.command-option {
  margin-right: 20px; /* Space between options when displayed side by side */
  cursor: pointer;
  text-shadow: 0 0 5px #ff6900;
}

.command-option:hover {
  text-shadow: 0 0 10px #ff6900;
}

.glowing-text-verified {
  color: #ff6900;
  text-shadow: 0 0 2px #ff6900, 0 0 4px #ff6900, 0 0 6px #ff6900;
}

.options {
  margin-top: 10px;
  display: flex;
  gap: 2em; /* Add space between the options */
}

.option {
  color: #ff6900;
  cursor: pointer;
  text-shadow: 0 0 5px #ff6900;
  font-size: 2em; /* Increase the font size */
  line-height: 28px;
}

.option:hover {
  text-decoration: underline;
}

.command {
  color: #ff6900;
  text-shadow: 0 0 5px #ff6900;
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 1em; /* Optional, adds space between GIF and text */
}

.centered-gif {
  width: 10em; 
  height: 10em;
}

/* Adjust options display on smaller screens */
@media screen and (max-width: 768px) {
  .options {
    flex-direction: column;
  }
}

.input-box {
  background-color: black;
  color: #48ff00;
  border: 2px solid #48ff00;
  padding: 0.5em;
  margin-right: 1em;
}

.wallet-input {
  margin-top: 20px;
}

.wallet-input button {
  cursor: pointer;
  background-color: black;
  color: #ff6900;
  border: 2px solid #ff6900;
  padding: 0.5em 1em;
}

.wallet-input button:hover {
  text-shadow: 0 0 10px #ff6900;
}


</style>
