// main.js
import { createApp } from 'vue';
import App from './App.vue';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import router from './router/router';  // Use the router from router.js

const firebaseConfig = {
  apiKey: "AIzaSyDD2G5g3cfaAuTl69w6YQUoqHIDYr8-zBI",
  authDomain: "sharkbods-a7015.firebaseapp.com",
  projectId: "sharkbods-a7015",
  storageBucket: "sharkbods-a7015.appspot.com",
  messagingSenderId: "1090870368516",
  appId: "1:1090870368516:web:31c23c477d638f2e8bd851",
  measurementId: "G-78XWM2TXJC"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export { db };

const app = createApp(App);
app.use(router);
app.provide('firebaseApp', firebaseApp);
app.provide('db', db);
app.mount('#app');
