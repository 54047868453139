import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../components/HomeViewSimple.vue';
import BattlerView from '../components/BattlerView.vue';

const routes = [
  { path: '/', component: HomeView },
  { path: '/battler', name: 'battler', component: BattlerView, props: route => ({ verifiedNFTs: route.query.verifiedNFTs }) }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
